module Main exposing (main)

import App as App
import Browser
import Browser.Navigation exposing (Key)
import Routing exposing (parseLocation)
import Url exposing (Url)



--import Time exposing (Time)


init : Flags -> Url -> Key -> ( App.Model, Cmd App.Msg )
init flags url key =
    App.init flags.version url key <| parseLocation url


type alias Flags =
    { version : String
    }


subscriptions : App.Model -> Sub App.Msg
subscriptions model =
    App.subscriptions model


view : App.Model -> Browser.Document App.Msg
view model =
    { title = "Visno"
    , body = [ App.view model ]
    }


main : Program Flags App.Model App.Msg
main =
    Browser.application
        { view = view
        , init = init
        , update = App.update
        , subscriptions = subscriptions
        , onUrlRequest = App.LinkClicked
        , onUrlChange = App.UrlChanged
        }
